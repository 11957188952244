<template>
  <b-row>

    <b-col
      md="12"
    >
      <b-card
        title="Transporteurs"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col
              md="12"
            >
              <b-card
                title="Transporteurs"
                no-body
              >
                Transporteurs
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="transporteurInternes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(disponible)="data">
            <template v-if="data.item.disponible">
              <span class="">Disponible</span>
            </template>
            <template v-else>
              <span class="">Non disponible </span>
            </template>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <!-- <span @click="$router.push({ name: 'espace-affreteur.details', params: { code: data.item.code} })">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle text-primary ml-50" />
            </span> -->
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-b-modal.validation
                @click="getTransporteur(data.item)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-success"
                />

                <span class="align-middle text-success ml-50"> Modifier</span>

              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.suspendre
                @click.prevent="getTransporteurId(data.item.id )"
              >
                <feather-icon
                  icon="XSquareIcon"
                  class="text-danger"
                />
                <span class="align-middle text-danger ml-50"> Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="transporteurInternes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <!-- form modal -->
    <b-modal
      id="validation"
      ref="my-modal"
      cancel-title="Annuler"
      ok-title="Modifier"
      title="Modification de transporteur interne"
      modal-class="modal-success"
      ok-variant="success"
      button-size="sm"
      size="lg"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOkForm"
    >
      <validation-observer
        ref="modifyTransporteurRules"
        tag="form"
      >
        <b-form @submit.prevent="handleSubmitModalForm">
          <b-row class="mt-2">
            <b-col md="4">
              <b-form-group
                label="Nom *"
                label-for="nom"
              >
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="nom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="transporteur.nom"
                      size="sm"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Prénom *"
                label-for="prenom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prenom"
                  rules="required"
                >
                  <b-form-input
                    v-model="transporteur.prenom"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Prénom"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Téléphone"
                label-for="telephone"
              >
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="téléphone"
                    rules="required"
                  >
                    <VuePhoneNumberInput
                      v-model="transporteur.telephone"
                      default-country-code="BJ"
                      show-code-on-list
                      size="sm"
                      :translations="configPhoneNumber"
                      @update="telephonefull = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Permis *"
                label-for="permis"
              >
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="permis"
                    rules="required"
                  >
                    <b-form-input
                      id="permis"
                      v-model="transporteur.permis"
                      size="sm"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Permis"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Numéro permis *"
                label-for="numero_permis"
              >
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="numero_permis"
                    rules="required"
                  >
                    <b-form-input
                      id="numero_permis"
                      v-model="transporteur.numero_permis"
                      size="sm"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Numéro permis"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Pièce *"
                label-for="piece"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pièce jointe"
                >
                  <b-form-file
                    id="fichier"
                    accept="image/*"
                    size="sm"
                    placeholder="Choisir un fichier"
                    @change="handleImageSelected"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- form modal -->
    <b-modal
      id="suspendre"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Suspension d'offre de frêt"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <form @submit.prevent="handleSubmitModal">
        <h6>Etes-vous sûr de vouloir suspendre ce transporteur interne?</h6>
      </form>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import _ from 'lodash'
import {
  required,
} from '@validations'

import {
  ref, onMounted, reactive, watch,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormInput,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BForm,
    BPagination,
    BInputGroup,
    BFormFile,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VuePhoneNumberInput,

  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      handleCreateTransporteur,
    } = useSocieteTransporteurs()

    const {
      imageUrl,
      imageFile,
      handleImageSelected,
      uploadFile,
      fileName,
      // deleteFile,
    } = useImageUpload()
    const image = ref({})

    const {
      updateTransporteur, deleteTransporteur, offres, loader,
      getTransporteurs, transporteurInternes, offreSuccess,
    } = useSocieteTransporteurs()

    const offreCode = ref('')
    const dataValidate = reactive({ statut: '' })
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }

    const fields = [
      {
        key: 'id', label: 'Id',
      },
      { key: 'nom', label: 'Transporteurs', sortable: true },
      { key: 'telephone', label: 'Téléphone', sortable: true },
      { key: 'permis', label: 'Permis', sortable: true },
      { key: 'disponible', label: 'Etat' },
      { key: 'actions' },
    ]
    const transporteur = reactive({
      id: null,
      nom: '',
      prenom: '',
      telephone: '',
      permis: '',
      numero_permis: '',
      piece: '',
      telephonefull: '',

    })

    // Obtenir un moyen de transport par son slug
    const getTransporteur = async item => {
      transporteur.id = item.id
      transporteur.nom = item.nom
      transporteur.prenom = item.prenom
      transporteur.telephone = item.telephone
      transporteur.permis = item.permis
      transporteur.numero_permis = item.numero_permis
      transporteur.piece = item.piece
    }
    const clearForm = () => {
      transporteur.id = null
      transporteur.nom = ''
      transporteur.prenom = ''
      transporteur.telephone = ''
      transporteur.permis = ''
      transporteur.numero_permis = ''
      transporteur.piece = ''
    }
    /* eslint-disable global-require */
    const items = transporteurInternes.value

    onMounted(async () => {
      await getTransporteurs()
      // Set the initial number of items
      totalRows.value = items.length
      console.clear()
    })

    const upload = async () => {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadFile(data)
      image.value = fileName.value
    }

    const createTransporteur = async () => {
      await upload()
      transporteur.piece = image.value
      handleCreateTransporteur(transporteur)
      console.clear()
    }

    const modifyTransport = async () => {
      await updateTransporteur(transporteur)
      await getTransporteurs()
      console.clear()
    }

    const TransporteurId = ref('')

    const getTransporteurId = id => {
      TransporteurId.value = id
    }
    const supprimerTransporteur = async id => {
      await deleteTransporteur(id)
    }
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const telephonefull = ref('')
    watch(() => (transporteur.telephonefull), () => {
      transporteur.telephone = ''
      transporteur.telephone = telephonefull.value.formattedNumber
      console.clear()
    })

    const formatter = value => value.toUpperCase()
    return {
      getTransporteur,
      supprimerTransporteur,
      modifyTransport,
      transporteurInternes,
      getTransporteurId,
      TransporteurId,

      transporteur,
      createTransporteur,
      imageUrl,
      handleImageSelected,
      required,

      perPage,
      clearForm,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,

      onFiltered,
      offreSuccess,
      formatter,
      loader,
      offres,
      offreCode,
      dataValidate,
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    handleOk(bvModalEvt) {
    // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleOkForm(bvModalEvt) {
    // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModalForm()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.supprimerTransporteur(this.TransporteurId)
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    async handleSubmitModalForm() {
    // Exit when the form isn't valid
      this.$refs.modifyTransporteurRules.validate().then(async success => {
        await this.modifyTransport()
        if (success) {
          // Hide the modal manually
          if (this.offreSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },
}
</script>
