/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useImageUpload() {
  const imageFile = ref('')
  const imageUrl = ref('')
  const fileName = ref('')
  const fil = ref('')
  const errors = ref([])
  const loader = ref(false)
  const { errorToast, successToast } = useAlertNotification()

  const uploadFile = async data => {
    loader.value = true

    await axiosClient.post('/upload-file', data)
      .then(response => {
        fileName.value = response.data.image
        console.log(response.data.image)
        console.log(fileName)
        loader.value = false

        successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadFileErrors = error.response.data.errors
        Object.keys(uploadFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadFileErrors[key][0])
        })
      })
  }

  const uploadExpertiseFile = async data => {
    loader.value = true
    try {
      const response = await axiosClient.post('/upload-file', data)
      // eslint-disable-next-line no-undef
      if (response.status === 200) {
        fil.value = response.data.image
        loader.value = false
        successToast('Enregistrement', 'Fichier chargé avec succès')
      }
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast('Oups! Erreur', uploadFileErrors[key][0])
      })
    }
  }
  const uploadExpertiseFileDetail = async data => {
    try {
      const response = await axiosClient.post('/file-societe-expertise', data)
      // eslint-disable-next-line no-undef
      if (response.status === 200) {
        fil.value = response.data.image

        successToast('Enregistrement', 'Fichier chargé avec succès')
      }
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast('Oups! Erreur', uploadFileErrors[key][0])
      })
    }
  }

  const deleteFile = async data => {
    await axiosClient.delete(`/remove-file/${data}`)
  }

  const handleImageSelected = event => {
    if (event.target.files.length === 0) {
      imageFile.value = ''
      imageUrl.value = ''
      return
    }

    imageFile.value = event.target.files[0]
  }

  watch(imageFile, imageFile => {
    if (!(imageFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(imageFile)

    fileReader.addEventListener('load', () => {
      imageUrl.value = fileReader.result
    })
  })

  return {
    imageFile,
    imageUrl,
    handleImageSelected,
    uploadFile,
    fileName,
    loader,
    deleteFile,
    uploadExpertiseFile,
    fil,
    uploadExpertiseFileDetail,
  }
}
